import React from "react"
import PropTypes from "prop-types"
import {Row, Col, Table, Card, Button, Spinner} from 'react-bootstrap';
class RegistrationDetail extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      deleteLoading: false
    }
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }

  handleEditClick (event) {
    this.setState({edit: !this.state.edit})
  }

  handleDeleteClick (event) {
    event.preventDefault()
    this.setState({deleteLoading: true});
    fetch('/api/v1/registrations/' + this.props.registration.id, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      
    })
    .then(response => response.json())
    .then(data => {this.setState({ deleteLoading: false, edit:false}), this.props.updateState('assets', data), this.props.updateState('selectedAsset', {}), this.props.hideComponent('hideAll')});
  }

  render () {
    const reg = this.props.registration
    return (
      <React.Fragment>
        <form onSubmit={this.props.handleRegUpdate}>
          <Card className="mt-2 bg-light">
            <fieldset disabled={!this.state.edit}>
            <Card.Body>
              <h5>Registration Detail:</h5>
              <Row>
                <Col sm={6}>
                  <Row noGutters="true">
                    <Col sm={4}>Product:</Col>
                    <Col sm={8}>{reg.product && reg.product.name}</Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={4}>Product Family:</Col>
                    <Col sm={8}>{reg.product && reg.product.product_family}</Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={4}>Description</Col>
                    <Col sm={8}>{reg.product && reg.product.description}</Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={4}>Primary Contact:</Col>
                    <Col sm={8}>{reg && reg.primary_contact}</Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={4}>Purchase Date:</Col>
                    <Col sm={8}><input type="date" name="purchaseDate" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={reg.purchase_date || ''} className="form-control" /></Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={4}>Registered Date:</Col>
                    <Col sm={8}>{reg && reg.install_date}</Col>
                  </Row>
                </Col>

                <Col sm={6}>
                  <Row noGutters="true">
                    <Col sm={3}>Sales Order #:</Col>
                    <Col sm={3}><input type="text" name="salesNo" id="salesNo" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={reg.sales_order_number || ''} className="form-control" /></Col>
                    <Col sm={2}>Cust. PO #:</Col>
                    <Col sm={4}><input type="text" name="custPo" id="custPo" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={reg.customer_po || ''} className="form-control" /></Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={4}>License:</Col>
                    <Col sm={8}>{reg.license_key}</Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={4}>Host ID/Serial #:</Col>
                    <Col sm={8}>{reg?.license_information?.host_id}</Col>
                  </Row>

                  <Row>
                    <Col sm={3}>QTY:</Col>
                    <Col sm={3}><input type="text" name="quantity"  id="quantity" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={reg.quantity} className="form-control" /></Col>
                    <Col sm={3}>Version:</Col>
                    <Col sm={3}>{reg.version_number}</Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={4}>Evaluation:</Col>
                    <Col sm={2}><input type="checkbox" name="evaluation" key={`${Math.floor((Math.random() * 1000))}-min`} defaultChecked={reg.evaluation} className="form-check-input" /></Col>
                    <Col sm={6}><input type="date" name="evalExpireDate" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={reg.evaluation_expiration_date || ''} className="form-control" /></Col>
                  </Row>

                  <Row noGutters="true">
                    <Col sm={2}>Maint:</Col>
                    <Col sm={4}><input type="date" name="maintStartDate" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={reg.maintenance_start_date || ''} className="form-control" /></Col>
                    <Col sm={4}><input type="date" name="maintEndDate" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={reg.maintenance_expiration_date || ''} className="form-control" /></Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
            </fieldset>
          </Card>

          <Card className="mt-2 mb-4 bg-light">
            <Card.Body>
              <Row>
                <Col sm={2}>
                  <div className="form-group form-check">

                    <input type="checkbox" defaultValue={this.state.edit} id="regEdit" className="form-check-input" onChange={this.handleEditClick} />
                    <label htmlFor="regEdit">Edit</label>
                  </div>

                </Col>

                <Col sm={4}>
                  <Button variant="success" type="submit" className="mr-2" disabled={!this.state.edit}>
                    Save
                    {this.props.regUpdateLoading ? <Spinner animation="border" /> : ""}
                  </Button>
                  
                  <Button variant="danger" disabled={!this.state.edit || this.state.deleteLoading} onClick={this.handleDeleteClick}>
                    {this.state.deleteLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                    {!this.state.deleteLoading && "Delete" }
                  </Button>

                </Col>
              </Row>
            </Card.Body>
          </Card>
        </form>

      </React.Fragment>
    );
  }
}

RegistrationDetail.propTypes = {
  registration: PropTypes.object
};
export default RegistrationDetail
