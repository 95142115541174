import React from "react"
import PropTypes from "prop-types"
import AccountLookup from "../components/AccountLookup"
import RegistrationDetail from "../components/RegistrationDetail"
import CreateLicense from "../components/CreateLicense"
import ViewLicense from "../components/ViewLicense"
import EmailLicenseNotification from "../components/EmailLicenseNotification"
import PlaceHolder from "../components/PlaceHolder"
import {Row, Col, Table, Card, Button} from 'react-bootstrap';
class Registration extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showRegistrationDetail: false,
      showCreateLicense: false,
      showViewLicense: false,
      viewLicenseLoading: false,
      removeLicenseLoading: false,
      showEmail: false,
      license: "",
    }
    this.hideComponent = this.hideComponent.bind(this);
    this.handleViewLicense = this.handleViewLicense.bind(this);
    this.handleRemoveLicense = this.handleRemoveLicense.bind(this);
    this.renderRegistrationRows = this.renderRegistrationRows.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
  }

  renderRegistrationRows(data) {
    let account_id = this.props.selectedAccount.id
    const filteredAssets = data.filter(function(asset) {
	    return asset.account_id == account_id
    })
    return filteredAssets && filteredAssets.map((a) =>
      (
        <tr key={a.id} onClick={() => {this.props.handleAssetTrClick(a); this.hideComponent("showRegistrationDetail")}} className={(this.props.selectedAsset.id === a.id ? 'table-success' : '')} style={{cursor: "pointer"}}>
          <td>{a.id}</td>
          <td>{a.kit}</td>
          <td>{a.primary_contact}</td>
          <td>{a.product.product_family}</td>
          <td>{a.product.name}</td>
          <td>{a.quantity}</td>
          <td>{a.order_type}</td>
          <td>{a.sales_order_number}</td>
          <td>{a.purchase_date}</td>
          <td>{a.maintenance_start_date}</td>
          <td>{a.maintenance_expiration_date}</td>
          <td>{a.evaluation ? "Yes" : "No"}</td>
          <td>{a.license_key ? a.license_key?.substring(0, 14) + "..." : ""}</td>
        </tr>
      ));
  }

  handleErrors(response) {
    if (!response.ok) {
      this.setState({viewLicenseLoading: false})
      this.hideComponent("showRegistrationDetail")
      console.log(response)
      throw Error(response.statusText);
    }
    return response;
  }

  handleViewLicense() {
    this.setState({viewLicenseLoading: true});
    const file_name = this.props.selectedAsset.license_key?.slice(0, -4)
    fetch('/api/v1/licenses/' + file_name, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    })
    .then(this.handleErrors)
    .then(response => response.json())
    .then(data => {this.setState({ license: data, viewLicenseLoading: false })});
    this.hideComponent("showViewLicense")
  }

  handleRemoveLicense() {
    this.hideComponent('hideAll')
    this.setState({removeLicenseLoading: true});
    // const file_name = this.props.selectedAsset.license_key?.slice(0, -4)
    fetch('/api/v1/licenses/' + this.props.selectedAsset.id, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
    })
    .then(response => response.json())
    .then(data => {this.setState({ removeLicenseLoading: false }), this.props.updateState("assets", data), this.props.updateState("selectedAsset", {})});
    // this.hideComponent("showViewLicense")
  }

  hideComponent(name) {
    
    switch (name) {
      case "showRegistrationDetail":
        this.setState({ showRegistrationDetail: true });
        this.setState({ showCreateLicense: false });
        this.setState({ showViewLicense: false });
        this.setState({ showEmail: false });
        break;
      case "showCreateLicense":
        this.setState({ showCreateLicense: true });
        this.setState({ showRegistrationDetail: false });
        this.setState({ showViewLicense: false });
        this.setState({ showEmail: false });
        break;
      case "showViewLicense":
        this.setState({ showViewLicense: true });
        this.setState({ showRegistrationDetail: false });
        this.setState({ showCreateLicense: false });
        this.setState({ showEmail: false });
        break;
      case "showEmail":
        this.setState({ showEmail: true });
        this.setState({ showRegistrationDetail: false });
        this.setState({ showCreateLicense: false });
        this.setState({ showViewLicense: false });
      break;
      case "hideAll":
        this.setState({ showViewLicense: false });
        this.setState({ showRegistrationDetail: false });
        this.setState({ showCreateLicense: false });
        this.setState({ showEmail: false });
        break;
      default:
        null;
    }
  }

  renderAccountRows(data) {
    if (!this.props.isEmpty(data)) {
      return data.map((a) =>
        (
          <tr key={a.id} onClick={() => {this.props.handleAccountTrClick(a, this.props.selectedContact.account_products); this.hideComponent('hideAll')}} className={(this.props.selectedAccount.SFDC_account_id === a.SFDC_account_id ? 'table-success' : '')} style={{cursor: "pointer"}}>
            <td>{a.name}</td>
          </tr>
        ));
    }
  }

  render () {
    return (
      <React.Fragment>
      <Card className="mt-2">
        <Card.Body>
          <Row>
            <Col sm={4}>
              <h6>Accounts:</h6>
              <Table bordered hover size="sm">
                <tbody>
                  {this.renderAccountRows(this.props.selectedContact.managed_accounts)}
                </tbody>
              </Table>
            </Col>
            <Col sm={8}>
              <Card className="bg-light">
                <Card.Body>
                  <p><strong>Region:</strong> {this.props.selectedAccount.region}</p>
                  <p><strong>Address:</strong> {this.props.selectedAccount.account_site_location}</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Body>
          Registered Product for Selected Account:
          <table className="table table-hover table-bordered table-sm">
            <thead>
                <tr>
                  <th>ID</th>
                  <th>Kit</th>
                  <th>Contact</th>
                  <th>Product Family</th>
                  <th>Product</th>
                  <th>QTY</th>
                  <th>Order</th>
                  <th>Sales Order</th>
                  <th>Purchase Date</th>
                  <th>Maint. Start</th>
                  <th>Maint. Expire</th>
                  <th>Eval Lic.</th>
                  <th>License</th>
                </tr>
            </thead>
            <tbody>
              {this.renderRegistrationRows(this.props.assets)}
            </tbody>
          </table>
          <ul className="list-inline">
            <li className="list-inline-item"><Button variant="dark" disabled={!this.props.selectedAsset?.id || !this.props.selectedAsset?.license_key} onClick={this.handleViewLicense}>View/Download License</Button>  </li>
            <li className="list-inline-item"><Button variant="dark" disabled={!this.props.selectedAsset?.id || this.props.selectedAsset?.license_information || !this.props.selectedAsset.product.can_be_licensed}  onClick={() => {this.hideComponent("showCreateLicense")}}>Create License</Button>  </li>
            <li className="list-inline-item"><Button variant="dark" disabled={!this.props.selectedAsset?.id || !this.props.selectedAsset?.license_information} onClick={this.handleRemoveLicense}>Remove License</Button>  </li>
            <li className="list-inline-item"><Button variant="dark" disabled={!this.props.selectedAsset?.id || !this.props.accountAdmins} onClick={() => {this.hideComponent("showEmail")}}>Email License Notification</Button>  </li>
            <li className="list-inline-item"><Button variant="dark" disabled>Refresh List</Button>  </li>
            <li className="list-inline-item"><Button variant="dark" disabled>Session Log</Button></li>
          </ul>
        </Card.Body>
      </Card>
      {!this.props.selectedAsset.id && <PlaceHolder greeting={"Please select a product to view registration detail"}></PlaceHolder>}
      {(this.state.showRegistrationDetail && this.props.selectedAsset.id) && <RegistrationDetail registration={this.props.selectedAsset} handleRegUpdate={this.props.handleRegUpdate} regUpdateLoading={this.props.regUpdateLoading} updateState={this.props.updateState} hideComponent={this.hideComponent} />}
      
      {this.state.showCreateLicense && <CreateLicense selectedAsset={this.props.selectedAsset} selectedAccount={this.props.selectedAccount} assets={this.props.assets} updateState={this.props.updateState} hideComponent={this.hideComponent} selectedContact={this.props.selectedContact} />}
      
      {this.state.removeLicenseLoading && <PlaceHolder greeting={"Deleting license file..."} loading={this.state.removeLicenseLoading}></PlaceHolder>}
      {this.state.viewLicenseLoading && <PlaceHolder greeting={"Getting license file..."} loading={this.state.viewLicenseLoading}></PlaceHolder>}
      {(this.state.showViewLicense && !this.state.viewLicenseLoading) && <ViewLicense license={this.state.license} />}
      {(this.state.showEmail) && <EmailLicenseNotification accountAdmins={this.props.accountAdmins[this.props.selectedAccount.SFDC_account_id]} hideComponent={this.hideComponent} />}
      </React.Fragment>


    );
  }
}

Registration.propTypes = {
  assets: PropTypes.array
};
export default Registration
