import React from "react"
import PropTypes from "prop-types"
class Account extends React.Component {

  constructor(props) {
    super(props)
  }

  renderAccountRows(data) {
    return data && data.map((a) =>
      (
        <tr key={a.id} onClick={() => this.props.handleAccountTrClick(a)} className={(this.props.selectedAccount.SFDC_account_id === a.SFDC_account_id ? 'table-success' : '')} style={{cursor: "pointer"}}>
          <td>{a.name}</td>
          <td>{a.address_1}</td>
          <td>{a.city}</td>
          <td>{a.state}</td>
          <td>{a.country}</td>
        </tr>
      )); // no need to bind with anon function
  }

  render () {
    return (
      <React.Fragment>
        <table className="table table-hover table-bordered table-sm">
          <thead>
              <tr>
                <th>Account</th>
                <th>Street</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
              </tr>
          </thead>
          <tbody>
            {this.renderAccountRows(this.props.accounts)}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default Account
