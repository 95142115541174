import React from "react"
import PropTypes from "prop-types"
import {Spinner, Card, Row, Col, Button} from 'react-bootstrap';
class PlaceHolder extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Card bg="light" text="dark" className="mt-2 mb-1 pt-4 pb-3">
          <Card.Body>
            <Card.Text className="text-center">
             {this.props.greeting}
             
            </Card.Text>
            {this.props.loading ? <span className="d-flex justify-content-center"> <Spinner animation="border" /> </span> : ""}
          </Card.Body>
        </Card>
        
      </React.Fragment>
    );
  }
}

PlaceHolder.propTypes = {
  greeting: PropTypes.string
};
export default PlaceHolder
