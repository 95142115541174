import React from "react"
import PropTypes from "prop-types"
import AccountLookup from "../components/AccountLookup"
import PlaceHolder from "../components/PlaceHolder"
import {Spinner, Card, Row, Col, Button} from 'react-bootstrap';
class ContactLookup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contacts: [],
      accountProducts: [],
      loading: false,
      showError: false,
      errorText: ""
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
  }

  handleFormSubmit (event) {
    this.setState({loading: true, contacts: [], showError: false});
    this.props.updateState('selectedContact', {})
    this.props.updateState('selectedAccount', {})
    this.props.updateState('assets', [])
    event.preventDefault();
    const data = new FormData(event.target);
    fetch('/api/v1/registrations/contact/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({"contact" : {"email" : data.get('contactemail'), "account_name" : data.get('accountname')}}),
    })
    .then(this.handleErrors)
    .then(response => response.json())
    .then(data => {this.setState({ contacts: data, loading: false })})
    .catch((error) => { console.log(error)});
  }
  
  handleErrors(response) {
    if (!response.ok) {
      response.text().then(text => this.setState({loading: false, showError: true, errorText: JSON.parse(text)}))
      console.log(response)
      throw Error(response.statusText);
    }
    return response;
  }

  renderContactRows(data) {
    return data.map((a) =>
      (
        <tr key={a.SFDC_contact_id} onClick={() => this.props.handleTrClick(a)} className={(this.props.selectedContact.SFDC_contact_id === a.SFDC_contact_id ? 'table-success' : '')} style={{cursor: "pointer"}}>
          <td>{a.SFDC_contact_id}</td>
          <td>{a.first_name} {a.last_name}</td>
          <td>{a.email}</td>
          <td>{a.account.name}</td>
          <td>{a.account.account_site_location}</td>
        </tr>
      )); // no need to bind with anon function
  }

  render () {
    return (
      <React.Fragment>
      <Card className="mt-2 mb-1">
        <Card.Body>
          1. Enter Account and/or Email information and click "Find" to search:
          <form onSubmit={this.handleFormSubmit}>
            <Row>
              <Col sm={8}>
                <div className="form-group">
                  <input id="contactemail" name="contactemail" placeholder='Enter Contact Email' className='form-control'/>
                </div>
                <div className="form-group">
                  <input id="accountname" name="accountname" placeholder='Enter Account Name' className='form-control'/>
                </div>
              </Col>
              <Col sm={4}>
                <Button variant="primary" type="submit">Find</Button>

              </Col>
            </Row>

          </form>
        </Card.Body>
      </Card>
      
      <Card className="mb-1">
        <Card.Body>
          2. Select a License Key Contact: (Click table row to select - Must select one contact to continue)
          {!this.state.contacts[0] && !this.state.showError && <PlaceHolder greeting={"Please search for a contact to continue"} loading={this.state.loading}></PlaceHolder>}
          { this.state.showError && 
              <Row>
                <Col sm={12}>
                  <p className="text-danger">{this.state.errorText.message} </p>
                </Col>
              </Row>
            }
          {this.state.contacts[0] && !this.state.showError && <table className="table table-hover table-bordered table-sm">
            <thead>
                <tr>
                    <th>Contact ID</th>
                    <th>Contact Name</th>
                    <th>Email</th>
                    <th>Contact's Account</th>
                    <th>Account Site</th>
                </tr>
            </thead>
            <tbody>
              {this.renderContactRows(this.state.contacts)}
            </tbody>
          </table> }
        </Card.Body>
      </Card>
      <Card className="mb-1">
        <Card.Body>
          3. Select an Account:
          {!this.props.selectedContact?.managed_accounts?.[0] && <PlaceHolder greeting={"Please select a contact that has managed accounts to continue"}></PlaceHolder>}
          {this.props.selectedContact?.managed_accounts?.[0] && <AccountLookup accounts={this.props.selectedContact.managed_accounts} handleAccountTrClick={this.props.handleAccountTrClick} selectedAccount={this.props.selectedAccount} selectedContact={this.props.selectedContact} />}
        </Card.Body>
      </Card>
      </React.Fragment>
    );
  }
}

export default ContactLookup
