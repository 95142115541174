import React from "react"
import PropTypes from "prop-types"
import { Multiselect } from 'multiselect-react-dropdown';
import { Card, Button, Row, Col } from 'react-bootstrap';
import PlaceHolder from "../components/PlaceHolder"


class EmailLicenseNotification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedEmails: [],
      loading: false
    }
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  onSelect(selectedList, selectedItem) {
    this.setState({selectedEmails: selectedList})
  }

  onRemove(selectedList, removedItem) {
    this.setState({selectedEmails: selectedList})
  }

  handleButtonClick() {
    this.setState({loading: true})
    let contactIds = this.state.selectedEmails.map(a => a.Id)
    fetch('/api/v1/registrations/email_notification', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({"email_notification" : {
        contact_id: contactIds,
      }}),
    })
    .then(response => response.json())
    .then(data => {this.setState({loading: false, selectedEmails: [] }), this.props.hideComponent('showRegistrationDetail')});
  }
  

  render () {
    
    return (
      <React.Fragment>
        { !this.state.loading && <Card className="mt-2 mb-2">
        
        <Card.Body>
          <Row>
            <Col sm={8}>
            <Multiselect
              options={this.props.accountAdmins} // Options to display in the dropdown
              onSelect={this.onSelect} // Function will trigger on select event
              onRemove={this.onRemove} // Function will trigger on remove event
              displayValue="Email" // Property name to display in the dropdown options
            />
            </Col>
            <Col sm={4}>
              <Button variant="primary" onClick={this.handleButtonClick} type="submit">Send Notification</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card> }
      {this.state.loading && <PlaceHolder greeting={"Sending email notifications..."} loading={this.state.loading}></PlaceHolder>}
      </React.Fragment>
    );
  }
}

export default EmailLicenseNotification
