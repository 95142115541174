import React from "react"
import PropTypes from "prop-types"
import {Card, Spinner} from 'react-bootstrap';
class AccountAdminLookup extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (!this.props.accountAdmins[this.props.accountId]) {
      this.props.getAccountAdmins(this.props.accountId)
    }
    
  }

  componentDidUpdate(prevProps) {
    if( this.props.accountId != prevProps.accountId && !this.props.accountAdmins[this.props.accountId]) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    {
      this.props.getAccountAdmins(this.props.accountId)
    }
  }

  render () {
    return (
      <React.Fragment>
        <Card bg={'light'} className="mt-1">
          <Card.Body>
            License Administrators for selected account
            <table className="table table-hover table-bordered table-sm">
              <thead>
                  <tr>
                    <th>Contact ID</th>
                    <th>Contact Name</th>
                    <th>Email</th>
                    <th>Contact's Account</th>
                    <th>Account Site</th>
                  </tr>
              </thead>
              <tbody>
              {this.props.accountAdmins[this.props.accountId]?.map((a) =>
                (
                  <tr key={a.Id}>
                    <td>{a.Id}</td>
                    <td>{a.Name}</td>
                    <td>{a.Email}</td>
                    <td>{a.Account.Name}</td>
                    <td>{a.Account.Account_Site_Location__c}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {this.props.loading ? <div className="d-flex justify-content-center"> <Spinner animation="border" /> </div> : ""}
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

AccountAdminLookup.propTypes = {
  accountId: PropTypes.string
};

export default AccountAdminLookup
