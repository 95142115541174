import React from "react"
import PropTypes from "prop-types"
class RegistrationLookup extends React.Component {
  render () {
    return (
      <React.Fragment>
        Assets: {this.props.assets}
      </React.Fragment>
    );
  }
}

RegistrationLookup.propTypes = {
  assets: PropTypes.object
};
export default RegistrationLookup
