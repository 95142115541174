import React from "react"
import PropTypes from "prop-types"
import {Row, Col, Table, Card, Button, Spinner} from 'react-bootstrap';
class LicenseDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false, 
      customer_note: this.props.selectedAsset.customer_note
    }
    
  }

  render () {
    return (
      <React.Fragment>
        
        <Card className="mt-2 bg-light">
          
          <Card.Body>
            
            <Row>
              <Col sm={6}>
                <Row noGutters="true">
                  <Col sm={4}>Product:</Col>
                  <Col sm={8}>{this.props.selectedAsset.product.name}</Col>
                </Row>
                <Row noGutters="true">
                  <Col sm={4}>Host ID:</Col>
                  <Col sm={8}>

                    {/* depending on when the data is loaded, license informations may be an array */}
                    {this.props.selectedAsset?.license_informations && this.props.selectedAsset?.license_informations[0]?.host_id }
                    {this.props.selectedAsset?.license_information && this.props.selectedAsset?.license_information?.host_id }
                  </Col>
                </Row>
              </Col>

              <Col sm={6}>
                <Row noGutters="true">
                  <Col sm={4}>Purchase Date</Col>
                  <Col sm={8}>{this.props.selectedAsset.purchase_date}</Col>
                </Row>

                <Row noGutters="true">
                  <Col sm={4}>SO#/PO#</Col>
                  <Col sm={8}>{this.props.selectedAsset.customer_po}</Col>
                </Row>

                <Row noGutters="true">
                  <Col sm={4}>Maintenance</Col>
                  <Col sm={8}>{this.props.selectedAsset.maintenance_start_date} to {this.props.selectedAsset.maintenance_expiration_date}</Col>
                </Row>

                
              </Col>
            </Row>
          </Card.Body>
          
        </Card>

        <Card className="mt-2 mb-4 bg-light">
          <Card.Body>
          <h5>Customer Note:</h5>
            <form onSubmit={this.props.handleLicenseUpdate}>
              <Row>
                <Col sm={1}>
                  <Button variant="success" type="submit" className="mr-2">
                    Save
                    {this.props.loading ? <Spinner animation="border" /> : ""}
                  </Button>
                </Col>
                
                <Col sm={6}>
                  <textarea rows="3" name="customer_note" id="customer_note" className="form-control" required onChange={(e) => {this.setState({customer_note: e.target.value})}} placeholder={this.props.selectedAsset.customer_note} />
                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
        
      </React.Fragment>
    );
  }
}

export default LicenseDetail
