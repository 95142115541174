import React from "react"
import PropTypes from "prop-types"
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import License from "./License"
import Account from "./Account"
import CreateLicense from '../CreateLicense'
import '../../styles/app'

class LicensePortalClient extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      key: "account",
      selectedAccount: {},
      selectedAsset: {},
      assets: [],
      showCreateLicense: false,
      loading: false
    }
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleAccountTrClick = this.handleAccountTrClick.bind(this)
    this.handleAssetTrClick = this.handleAssetTrClick.bind(this)
    this.updateState = this.updateState.bind(this)
    this.hideComponent = this.hideComponent.bind(this)
    this.handleLicenseUpdate = this.handleLicenseUpdate.bind(this)
  }

  updateState (stateKey, stateValue) {
    this.setState({[stateKey] : stateValue})
  }

  hideComponent(name) {
    // add logic here for tab change
    if (name === 'hideAll') {
      this.setState({showCreateLicense: false})
      this.handleTabChange('license')
    }
  }

  handleTabChange (key) {
    this.setState({key})
  }

  handleAccountTrClick (account) {
    const filteredAssets = account.account_products.filter(function(asset) {
	    return asset.account_id == account.id
    })
    this.setState({selectedAccount: account, assets: filteredAssets, selectedAsset: {}, showCreateLicense: false})
    this.handleTabChange('license')
  }

  handleLicenseUpdate (event) {
    event.preventDefault()
    const data = new FormData(event.target)
    this.setState({loading: true});
    fetch('/api/v1/clients/' + this.state.selectedAsset.id, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrf},
      body: JSON.stringify({"account_product" : {
        "customer_note" : data.get('customer_note'),
        "contact_id" : this.props.contact.id
      }}),
    })
    .then(response => response.json())
    .then(data => {this.setState({ selectedAsset: {}, assets: data, loading: false })});
  }

  handleAssetTrClick (asset) {
    this.setState({selectedAsset: asset})
  }

  render () {
    return (
      <React.Fragment>
        <Tabs activeKey={this.state.key} onSelect={(key) => this.handleTabChange(key)} id="uncontrolled-tab-example">

        <Tab eventKey="account" title="Account Information">
          <Account className="mt-2" handleAccountTrClick={this.handleAccountTrClick} selectedAccount={this.state.selectedAccount} accounts={this.props.contact.accounts}  />
        </Tab>

        <Tab eventKey="license" title="Product License" disabled={!this.state.selectedAccount.id} >
          {this.state.selectedAccount.id && <License handleAccountTrClick={this.handleAccountTrClick} selectedAccount={this.state.selectedAccount}  handleAssetTrClick={this.handleAssetTrClick} contact={this.props.contact} assets={this.state.assets} selectedAsset={this.state.selectedAsset} updateState={this.props.updateState} handleTabChange={this.handleTabChange} updateState={this.updateState} handleLicenseUpdate={this.handleLicenseUpdate} loading={this.state.loading} />}
        </Tab>

        {this.state.showCreateLicense && <Tab eventKey="create-license" title="Create License">
        <CreateLicense selectedAsset={this.state.selectedAsset} selectedAccount={this.state.selectedAccount} assets={this.state.assets} updateState={this.updateState} hideComponent={this.hideComponent} handleTabChange={this.handleTabChange} selectedContact={this.props.contact} />
        </Tab>}

        </Tabs>
      </React.Fragment>
    );
  }
}

export default LicensePortalClient
