import React from "react"
import PropTypes from "prop-types"
import {Row, Col, Card, Button, Spinner} from 'react-bootstrap';
class ViewLicense extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }
  render () {
    return (
      <React.Fragment>
        <Card className="mt-2 mb-2">
        
        <Card.Body>
          <textarea rows="3" className="form-control" value={this.props.license.license} readOnly= {true}/>
          
          <a href={this.props.license.license_url} className="btn btn-success">Download License</a>
        </Card.Body>
      </Card>
        
      </React.Fragment>
    );
  }
}

export default ViewLicense
