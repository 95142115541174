import React from "react"
import PropTypes from "prop-types"
import LicenseDetail from "./LicenseDetail"
import {Row, Col, Table, Card, Button} from 'react-bootstrap';
class License extends React.Component {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  createLicense() {
    this.props.updateState("showCreateLicense", true)
    this.props.handleTabChange("create-license")
  }

  renderAccountRows(data) {
    
    return data.map((a) =>
      (
        <tr key={a.id} onClick={() => this.props.handleAccountTrClick(a, a.account_products)} className={(this.props.selectedAccount.SFDC_account_id === a.SFDC_account_id ? 'table-success' : '')} style={{cursor: "pointer"}}>
          <td>{a.name}</td>
        </tr>
      ));
    
  }

  renderRegistrationRows(data) {
    let account_id = this.props.selectedAccount.id
    const filteredAssets = data.filter(function(asset) {
	    return asset.account_id == account_id
    })
    return filteredAssets && filteredAssets.map((a) =>
      (
        <tr key={a.id} onClick={() => {this.props.handleAssetTrClick(a);}} className={(this.props.selectedAsset.id === a.id ? 'table-success' : '')} style={{cursor: "pointer"}}>
          <td>{a.product.product_family}</td>
          <td>{a.product.name}</td>
          <td>{a.quantity}</td>
          <td>{a.purchase_date}</td>
          <td>{a.maintenance_expiration_date}</td>
          <td>{a.license_url ? <a href={a.license_url} className={'btn btn-primary btn-sm'}>Download License</a> : <Button className={a.can_be_licensed ? "btn-sm" : "d-none"} onClick={() => this.createLicense()}>Create License</Button>}</td>
        </tr>
      ));
  }

  render () {
    return (
      <React.Fragment>
        <Card className="mt-2">
        <Card.Body>
          <Row>
            <Col sm={4}>
              <h6>Accounts:</h6>
              <Table bordered hover size="sm">
                <tbody>
                  {this.renderAccountRows(this.props.contact.accounts)}
                </tbody>
              </Table>
            </Col>
            <Col sm={8}>
              <Card className="bg-light">
                <Card.Body>
                  <p><strong>Account</strong> {this.props.selectedAccount.name}</p>
                  
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Body>
          Registered Product for Selected Account:
          <table className="table table-hover table-bordered table-sm">
            <thead>
                <tr>
                  <th>Product Family</th>
                  <th>Product</th>
                  <th>QTY</th>
                  <th>Purchase Date</th>
                  <th>Maint. Expire</th>
                  <th>License</th>
                </tr>
            </thead>
            <tbody>
              {this?.renderRegistrationRows(this.props.assets)}
            </tbody>
          </table>
          
        </Card.Body>
      </Card>

      { this.props.selectedAsset?.id && <LicenseDetail selectedAsset={this.props.selectedAsset} handleLicenseUpdate={this.props.handleLicenseUpdate} loading={this.props.loading}></LicenseDetail>}
      </React.Fragment>
    );
  }
}

export default License
