import React from "react"
import PropTypes from "prop-types"
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {Spinner, Card, Row, Col, Button} from 'react-bootstrap';
import filterFactory, { multiSelectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { data } from "jquery";

class NewRegistration extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      selectedProduct: [],
      newAccountProducts: [],
      savedAccountProducts: {},
      newRegError: "",
      showError: false
    }
    this.handleProductTrClick = this.handleProductTrClick.bind(this)
    this.deleteSelectedProduct = this.deleteSelectedProduct.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
  }

  deleteSelectedProduct(productName) {
    this.setState((state, props) => ({
      selectedProduct: state.selectedProduct.filter(product => product.name != productName)
    }));
  }

  handleProductTrClick (row, products, isSelect) {
    let currentProduct = products.filter(product => product.id == row.id);
    if (isSelect) {
      this.setState((state, props) => ({
        selectedProduct: state.selectedProduct.concat(currentProduct)
      }));
    } else {
      this.deleteSelectedProduct(currentProduct[0]['name'])
    }
  }

  handleErrors(response) {
    if (!response.ok) {
      this.setState({loading: false, showError: true})
      console.log(response);
      throw Error(response);
    }
    return response;
  }

  handleFormSubmit (event) {
    event.preventDefault();
    this.setState({loading: true});
    // this.state.newAccountProducts.forEach(createAccountProducts);
    const data = new FormData(event.target)
    fetch('/api/v1/registrations/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({"account_product" : {
        account_id: this.props.selectedAccount.id,
        contact_id: this.props.selectedContact.id,
        order_type: data.get('orderType'),
        order_company: data.get('orderCompany'),
        order_number: data.get('orderNumber'),
        selected_products: this.state.selectedProduct
      }}),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then(data => {this.setState({loading: false, selectedProduct: [], showError: false}), this.props.handleTabChange("registration"), this.props.updateState("assets", data), document.getElementById("so_form").reset()});
      } else {
        try {
          return response.json().then((json) => {
            console.log(json)
            throw Error(json.error)
          });
        } catch (error) {
          throw Error("There was an error in retrieving and saving this record. Please double check the order type, company and SO and try again.")
        }
      }
    })
    .catch((err) => {
      this.setState({loading: false, showError: true, newRegError: String(err)})
      console.log(err);
    });
    

  }

  renderProductRows(data) {
    return products.map((a) =>
      (
        <tr key={a.id} onClick={() => this.handleProductTrClick(a)} className={(this.state.selectedProduct.id === a.id ? 'table-success' : '')} style={{cursor: "pointer"}}>
          <td>{a.product_family}</td>
          <td>{a.name}</td>
          <td>{a.description}</td>

        </tr>
      ));
  }

  render () {
    const products = JSON.parse(this.props.products);
    const { SearchBar } = Search;
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: '#c3e6cb',
      selected: this.state.selectedProduct.map(a => a.id),
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handleProductTrClick(row, products, isSelect)
      }
    };
    const columns = [{
        dataField: 'product_family',
        text: 'Product Family',
        sort: true,

      }, {
        dataField: 'name',
        text: 'Product Name',
        sort: true,
      }, {
        dataField: 'description',
        text: 'Description'
    }];
    return (
      <React.Fragment>
      <Card className="mb-1">
        <Card.Body> 
          Available Product Selection:
          <ToolkitProvider
            keyField="id"
            data={ products }
            columns={ columns }
            bootstrap4
            search
          >
            {
              props =>
              <div>
                <SearchBar { ...props.searchProps } />
                <hr />
                <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } selectRow={ selectRow } condensed  />
              </div>
            }
          </ToolkitProvider>
        </Card.Body>
      </Card>

      <Card className="mb-1">
        <Card.Body>
          <h5>New Products to be Registered:</h5>

          Sales Order Number
          <form id="so_form" onSubmit={this.handleFormSubmit}>
            <Row>
              {/* <Col sm={3}>
                <select id="orderCompany" name="orderCompany" placeholder='Company' className='form-control' required>
                  <option value="00201">00201 - Raritan America</option>
                  <option value="00105">00105 - Raritan Taiwan</option>
                  <option value="00103">00103 - Raritan Japan</option>
                  <option value="00401">00401 - Raritan Europe</option>
                </select>
              </Col>
              <Col sm={3}>
              <select id="orderType" name="orderType" placeholder='Type' className='form-control' required>
                  <option value="SO">SO</option>
                  <option value="S2">S2</option>
                  <option value="SG">SG</option>
                  <option value="S1">S1</option>
                  <option value="S9">S9</option>
                  <option value="SV">SV</option>
                </select>
              </Col> */}
              <Col sm={4}>
                <input type="number" min="1" step="1" id="orderNumber" name="orderNumber" placeholder='Number' className='form-control' required/>
              </Col>
              <Col sm={4}>
                <Button variant="primary" type="submit" disabled={this.state.loading} >
                    {this.state.loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                    {this.state.loading && "Retrieving Order..." }
                    {!this.state.loading && "Retrieve Order & Save" }
                  </Button>
              </Col>
            </Row>
            { this.state.showError && 
              <Row>
                <Col sm={12}>
                  <p className="text-danger">{this.state.newRegError}</p>
                  {/* <p className="text-danger">There was an error in retrieving and saving this record. Please double check the order type, company and SO and try again.</p> */}
                </Col>
              </Row>
            }

          </form>

          <table className="table table-hover table-bordered table-sm">
            <thead>
                <tr>
                  <th>Product Family</th>
                  <th>Product</th>
                  <th>Qty</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {this.state.selectedProduct.map((a) =>
              (
                <tr key={a.id}>
                  <td>{a.product_family}</td>
                  <td>{a.name}</td>
                  <td></td>
                  <td>{a.description}</td>
                  <td><Button variant="danger" onClick={() => this.deleteSelectedProduct(a.name)}>X</Button></td>
                </tr>
              ))}
            </tbody>
          </table>

        </Card.Body>
      </Card>

      </React.Fragment>
    );
  }
}

NewRegistration.propTypes = {
  products: PropTypes.string
};
export default NewRegistration
