import React from "react"
import PropTypes from "prop-types"
import Customer from "./Customer"
import Registration from "./Registration"
import NewRegistration from "./NewRegistration"
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import '../styles/app'
class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedContact: {},
      selectedAccount: {},
      selectedAsset: {},
      regUpdateLoading: false,
      selectedAccountProducts: [],
      assets: [],
      accountAdmins: [],
      assetLoading: false,
      accountAdminLoading : false,
      key: "customer"
    }

    this.handleTrClick = this.handleTrClick.bind(this)
    this.handleAccountTrClick = this.handleAccountTrClick.bind(this)
    this.handleAssetTrClick = this.handleAssetTrClick.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleRegUpdate = this.handleRegUpdate.bind(this)
    this.updateState = this.updateState.bind(this)
    this.getAccountAdmins = this.getAccountAdmins.bind(this)
  }

  handleTrClick (contact, accountProducts) {
    this.setState({selectedContact: contact, selectedAccount: {}, selectedAsset: {}})
  }

  updateState (stateKey, stateValue) {
    this.setState({[stateKey] : stateValue})
  }

  handleAccountTrClick (account) {
    // const filteredAssets = this.state.selectedContact.account_products.filter(function(asset) {
	  //   return asset.account_id == account.id
    // })
    if (!this.state.assets[0]) {
      this.setState({assets: this.state.selectedContact.account_products})
    }
    this.setState({selectedAccount: account, selectedAsset: {}})
  }

  handleAssetTrClick (asset) {
    this.setState({selectedAsset: asset})
  }

  handleTabChange (key) {
    this.setState({key})
  }

  handleRegUpdate (event) {
    event.preventDefault()
    const data = new FormData(event.target)
    const evaluation = data.get('evaluation') == 'on' ? true : false 
    this.setState({regUpdateLoading: true});
    fetch('/api/v1/registrations/' + this.state.selectedAsset.id, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({"account_product" : {
        "purchase_date" : data.get('purchaseDate'),
        "customer_po" : data.get('custPo'),
        "sales_order_number" : data.get('salesNo'),
        "evaluation_expiration_date" : data.get('evalExpireDate'),
        "evaluation" : evaluation,
        "quantity" : data.get('quantity'),
        "maintenance_start_date" : data.get('maintStartDate'),
        "maintenance_expiration_date" : data.get('maintEndDate'),
      }}),
    })
    .then(response => response.json())
    .then(data => {this.setState({ selectedAsset: {}, assets: data, regUpdateLoading: false })});
  }

  getAccountAdmins (accountId) {
    this.setState({accountAdminLoading: true});
    fetch('/api/v1/registrations/account/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({"account" : { "account_id" : this.state.selectedAccount.SFDC_account_id }}),
    })
    .then(response => response.json())
    .then(data => {
      this.setState(prevState => ({
        accountAdmins: {...prevState.accountAdmins, [this.state.selectedAccount.SFDC_account_id]: data}

        
      }))
      this.setState({accountAdminLoading:false})
    });
  }

  isEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  render () {
    return (
      <React.Fragment>

      <Tabs activeKey={this.state.key} onSelect={(key) => this.handleTabChange(key)} id="uncontrolled-tab-example">

        <Tab eventKey="customer" title="Identify Customer">
          <Customer handleTrClick={this.handleTrClick} handleAccountTrClick={this.handleAccountTrClick} handleTabChange={this.handleTabChange} selectedContact={this.state.selectedContact} selectedAccount={this.state.selectedAccount} updateState={this.updateState} isEmpty={this.isEmpty} getAccountAdmins={this.getAccountAdmins} accountAdmins={this.state.accountAdmins} accountAdminLoading={this.state.accountAdminLoading}  className="mt-2" />
        </Tab>

        <Tab eventKey="registration" title="Registration Information" disabled={this.isEmpty(this.state.selectedAccount)}>
          <Registration assets={this.state.assets} handleAccountTrClick={this.handleAccountTrClick} handleAssetTrClick={this.handleAssetTrClick} selectedAccount={this.state.selectedAccount} selectedContact={this.state.selectedContact} selectedAccountProducts={this.state.selectedAccountProducts} selectedAsset={this.state.selectedAsset} isEmpty={this.isEmpty} handleRegUpdate={this.handleRegUpdate} regUpdateLoading={this.state.regUpdateLoading} updateState={this.updateState} accountAdmins={this.state.accountAdmins} />
        </Tab>

        <Tab eventKey="register-new" title="Register New Product" disabled={this.isEmpty(this.state.selectedAccount)}>
          <NewRegistration assets={this.state.assets} products={this.props.products} selectedAccount={this.state.selectedAccount} selectedContact={this.state.selectedContact} selectedAsset={this.state.selectedAsset} isEmpty={this.isEmpty} handleTabChange={this.handleTabChange} updateState={this.updateState} />
        </Tab>
      </Tabs>

      </React.Fragment>
    );
  }
}

export default App
