import React from "react"
import PropTypes from "prop-types"
import AccountLookup from "./AccountLookup"
import ContactLookup from "./ContactLookup"
import RegistrationLookup from "./RegistrationLookup"
import AccountAdminLookup from "./AccountAdminLookup"
import {Row, Col, Button} from 'react-bootstrap';

class Customer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      account: {},
      contact: {}
    }
  }

  render () {
    return (
      <React.Fragment>
        <ContactLookup handleTrClick={this.props.handleTrClick} handleAccountTrClick={this.props.handleAccountTrClick} selectedContact={this.props.selectedContact} selectedAccount={this.props.selectedAccount} updateState={this.props.updateState} />
        <Row>
          <Col sm={10}> {
            this.props.isEmpty(this.props.selectedAccount) ? "Select An Account to view account admins" : <AccountAdminLookup accountId={this.props.selectedAccount.SFDC_account_id} getAccountAdmins={this.props.getAccountAdmins} accountAdmins={this.props.accountAdmins} loading={this.props.accountAdminLoading} />
          } </Col>
          <Col sm={2}>
            <Button variant="primary" block onClick={() => this.props.handleTabChange('registration')} disabled={this.props.isEmpty(this.props.selectedAccount)}>View Registration</Button>
            <Button variant="primary" block onClick={() => this.props.handleTabChange('register-new')} disabled={this.props.isEmpty(this.props.selectedAccount)}>New Registration</Button>
            <Button variant="success" block onClick={() => window.open('/api/v1/clients/' + this.props.selectedContact.SFDC_contact_id,'_blank')} disabled={this.props.isEmpty(this.props.selectedContact)}>Customer's View</Button>

          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Customer
