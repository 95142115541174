import React from "react"
import PropTypes from "prop-types"
import PlaceHolder from "../components/PlaceHolder"
import {Row, Col, Table, Card, Button, Spinner} from 'react-bootstrap';
class CreateLicense extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      license: {},
      selectedBaseProduct: {}
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleTrClick = this.handleTrClick.bind(this)
    this.handleC4License = this.handleC4License.bind(this)
    this.getBaseProducts = this.getBaseProducts.bind(this)
    
  }

  handleFormSubmit (event) {
    event.preventDefault()
    const data = new FormData(event.target)
    this.setState({loading: true});
    fetch('/api/v1/licenses', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({"license" : {
        "host_id_1" : data.get('hostId1'),
        "host_id_2" : data.get('hostId2'),
        "account_product_id" : this.props.selectedAsset.id,
        "contact_id" : this.props.selectedContact.id
      }}),
    })
    .then(response => response.json())
    .then(data => {this.setState({ loading: false }), this.props.updateState("assets", data), this.props.hideComponent('hideAll'), this.props.updateState("selectedAsset", {})});
  }

  handleC4License () {
    this.setState({loading: true});
    fetch('/api/v1/licenses', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({"license" : {
        "account_product_id" : this.props.selectedAsset.id,
        "base_product_id" : this.state.selectedBaseProduct.id,
        "contact_id" : this.props.selectedContact.id
      }}),
    })
    .then(response => response.json())
    .then(data => {this.setState({ loading: false }), this.props.updateState("assets", data), this.props.hideComponent('hideAll'), this.props.updateState("selectedAsset", {})});
  }

  handleTrClick(product) {
    this.setState({selectedBaseProduct: product})
  }

  getBaseProducts(products) {
    var filteredProducts = products.filter(obj => {
      if (obj.license_informations) {
        obj.license_information = obj.license_informations[0]
      }
      return (obj.product.base_product === true && obj.license_information?.host_id && obj.account_id == this.props.selectedAccount.id)
    })

    if (filteredProducts.length > 0) {
      return filteredProducts
    } else {
      return false
    }

  }

  renderBaseProducts(baseProducts) {
    var filteredProducts = this.getBaseProducts(baseProducts)
    return filteredProducts.map((a) => (     
      <tr key={a.id} onClick={() => this.handleTrClick(a)} className={(this.state.selectedBaseProduct.id === a.id ? 'table-success' : '')} style={{cursor: "pointer"}}>
          <td>{a.id}</td>
          <td>{a.product.name}</td>
          <td><pre>{a.license_information?.host_id}</pre></td>
        </tr>
    ))
  }

  render () {
    const asset = this.props.selectedAsset
    // const licenseFormat = this.props.selectedAsset.product.product_configs[0].license_format
    const licenseFormat = this.props?.selectedAsset?.product?.license_format
    return (
      <React.Fragment>
      {this.state.loading && <PlaceHolder greeting={"Creating license file..."} loading={this.state.loading}></PlaceHolder>}
      {!this.state.loading &&
        <Card>
          <Card.Header>{asset.product.description}</Card.Header>
          <Card.Body>
            <Card.Title></Card.Title> 
            { licenseFormat !== 'C-4' && <form onSubmit={this.handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="hostId1">Host Id 1:</label>
                <input type="text" name="hostId1" id="hostId1" className="form-control" required />
              </div>
              
              {
                licenseFormat === 'C-1' && 
                  <div className="form-group"> 
                    <label htmlFor="hostId2">Host Id 2:</label>
                    <input type="text" name="hostId2" id="hostId2" className="form-control" required />
                  </div> 
              }
              
              <Button variant="primary" type="submit">Create License</Button>
            </form>
            }
            
            { (licenseFormat === 'C-4' && this.getBaseProducts(this.props.assets)) &&
              <div>
                <table className="table table-hover table-bordered table-sm">
                  <thead>
                      <tr>
                          <th>#</th>
                          <th>Server Product</th>
                          <th>Host ID</th>
                      </tr>
                  </thead>
                  <tbody>
                    {this.renderBaseProducts(this.props.assets)}
                  </tbody>
                </table>
                <Button disabled={!this.state.selectedBaseProduct?.product?.name} variant="primary" onClick={this.handleC4License}>Create License</Button>
              </div>
            }
            { (licenseFormat === 'C-4' && !this.getBaseProducts(this.props.assets)) &&
              <div>
                <p>This is an add-on license. There's no server license available. Please select a server product and create a license prior to creating an add-on license.</p>
              </div>
            }

            
          </Card.Body>
        </Card>
      }
      </React.Fragment>
    );
  }
}

export default CreateLicense
